@if (href() && !disabled()) {
  <a [href]="href()" target="_blank">
    <ng-container [ngTemplateOutlet]="content"/>
    <mat-icon style="font-size: inherit;">open_in_new</mat-icon>
  </a>
} @else {
  <span>
    <ng-container [ngTemplateOutlet]="content"/>
  </span>
}

<ng-template #content>
  <ng-content/>
</ng-template>
